import "./Contestants.css"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Constants from "../../../Components/UiKit/Constants";
import Container from 'react-bootstrap/Container';
import { Row, Col, Nav } from 'react-bootstrap';
import PathConstants from "../../../Helper/Routes/PathConstants";
import useFetch from "../../../Helper/useFetch";


const Contestants = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const apiUrl = process.env.REACT_APP_VOICE_API
    const location = useLocation();
    const category = location?.state?.category;
    const eventId = location?.state?.eventId// Use the correct key "category"
    console.log("Props received in Contestants:", location.state);
    const [data, isPLoading, getData] = useFetch(`${apiUrl}home/contestant/event/${eventId}/category/${id}`, [], true);



    return (
        <>

            <div className="categoryBanner">
                <div className="container text-center">
                    <h3>{category}</h3>


                </div>

            </div>
            <div className="eventList">
                <div className='ongoingEventSection sectionPadding'>
                    <h2 className='heading'>Contestants</h2>
                    <Container>
                        <Row>
                            {/* {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-3'>
                                    <div className='categoryBox'>
                                        {/* <div className='contestantBox'>Paid</div> */}
                            {/* <button type="button" className="themeBtn voteBtn">Vote</button> */}
                            {/* <img src={apiUrl + i.image} alt="event" crossOrigin="anonymous" />
                            <div className='categoryDetails'>


                                <h5>{i.firstname}</h5>
                                <h5>{i.lastname}</h5>
                            </div>

                            <button type="button" className="themeBtn voteBtn" onClick={() => navigate(PathConstants.CONTESTANTSVOTE.replace(":id", i.id))}>Vote</button>
                        </div>
                    </Col> */}


                            {data?.data.map(i => (
                                <Col key={i.id} className='col-12 col-sm-4 col-md-3'>
                                    <div className='eventBox'>


                                        <div className='contestantDtls'>
                                            {/* <img src={apiUrl + i.image} alt="event" crossOrigin="anonymous" /> */}
                                            <div className="contestantImage">
                                                <img src={`http://3.7.236.153:8001/api/${i.image}`} style={{ borderRadius: "10px" }} alt="event" crossOrigin="anonymous" />
                                            </div>
                                            {/* <div>
                                                {new Date(i.fromTime).toLocaleTimeString()}
                                            </div> */}

                                            {/* <div className='categoryDetails'> */}


                                            <h5 className="contestantName">{i.firstname} {i.lastname}</h5>

                                            <p className="contestantState">{i.state ? i.state : "NA"}</p>
                                            <button type="button" className="contestantVote" onClick={() =>
                                                navigate(PathConstants.CONTESTANTSVOTE.replace(":id", i.id), {
                                                    state: {
                                                        firstname: i.firstname,
                                                        lastname: i.lastname,
                                                        shortCode: i.shortCode,
                                                        image: i.image
                                                    },
                                                })
                                            }>Vote</button>
                                            {/* </div> */}

                                        </div>
                                    </div>
                                </Col>
                            ))}




                        </Row>
                    </Container>
                    <img src={Constants.VECTOR1} alt="event" className='vector1Img' />
                </div >
            </div >
        </>
    )


}

export default Contestants;